module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"THE HQ | Startup-Investor Engagement Platform","short_name":"THE HQ","start_url":"/","background_color":"#FFFFFF","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/hq-favicon-512x512.png"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://dd1c88a35625400b89631cfbae01fd4e@sentry.io/1493879","environment":"production","enabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-129657062-2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
